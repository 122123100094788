$desktop-width: 1024px;

body.has-backdrop #root {
  filter: blur(8px);
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .app-content {
    flex: 1;

    padding-bottom: 70px;
  }

  select {
    font-size: 12pt;

    option:disabled {
      color: #999;
    }
  }
}

.app-footer {
  text-align: center;
  background-color: #f7f7f7;
  padding: 15px 0;
  font-size: 11pt;
  position: fixed;
  bottom: 0;
  left: 0;
}

.mobile-bottom-nav {
  margin-top: 20px;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.auth-nav-bar {
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  font-size: 10pt;
}

.app-loader-container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.app-client-logo {
  width: 250px;
  height: 80px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.home-greeting {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.home-text {
  margin-bottom: 20px;
  text-align: center;
}

.action-detail {
  text-align: center;

  h3 {
    text-transform: uppercase;
    font-size: 10pt;
    font-weight: bold;
    margin: 0;
  }

  h4 {
    margin: 0;
    margin-top: 4px;
    color: #c00;
    font-size: 16pt;
  }
}

.action-list {
  .action-container {
    text-align: center;
    max-width: 600px;
    margin: auto;

    .action-button {
      margin-top: 30px;
    }
  }
}

.payment-form {
  max-width: 600px;
  padding: 0 24px;
  margin: auto;

  .balance-header {
    text-align: center;
    text-transform: uppercase;
    font-size: 10pt;
    font-weight: bold;
    margin: 0;
  }

  .balance-due {
    text-align: center;
    margin: 0;
    margin-top: 4px;
    color: #c00;
    font-size: 16pt;
  }

  .payment-fields {
    margin-top: 40px;

    > div {
      margin-bottom: 40px;

      label {
        text-align: center;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.login-form {
  max-width: 600px;
  padding: 0 40px;
  margin: auto;

  .login-header {
    text-align: center;
    font-weight: bold;
  }

  .login-fields {
    margin-top: 40px;

    > div {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.statements-container, .profile-container, .payment-stepper-container, .home-container, .payment-actions-container {
  max-width: 600px;
  padding: 0 24px;
  margin: auto;

  .statements-filter {
    .statements-filter-header {
      text-align: center;
    }
  }
}


.profile-view-container {
  padding: 0 24px;
}

.statement-container {
  margin-top: 28px;
  text-align: center;

  .statement-header {
    margin-bottom: 10px;
  }

  .statement-date {
    font-weight: bold;
  }

  .balance-header {
    text-transform: uppercase;
  }

  .balance-due {
    color: #c00;
    font-weight: bold;
  }

  .statement-amounts {
    width: 100%;
    text-align: left;
  }

  .statement-recipient {

  }

  .print-button {
    margin-top: 60px;
    .MuiButton-label {
      font-weight: 500 !important;
    }
  }
}

.MuiStepper-root {
  padding: 0 !important;
}

.MuiButton-fullWidth {
  text-transform: none !important;
  line-height: 2 !important;
  font-weight: normal !important;
  font-size: .9rem !important;
}

.payment-confirmation-status-message {
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
}

.payment-confirmation-message {
  color: #666;
  margin-bottom: 20px;
  text-align: center;
  font-size: 11pt;
}

.payment-confirmation-group {
  display: flex;
  margin-bottom: 6px;
  font-size: 11pt;

  .payment-confirmation-label {
    flex: 1;
    padding-right: 10px;
  }

  .payment-confirmation-value {
    text-align: right;
  }
}

.stepper-checkbox-field {
  .MuiFormControlLabel-root {
    align-items: flex-start;
  }
}

.header-h3 {
  font-size: 11pt !important;
  font-weight: 500 !important;
}

.header-h4 {
  font-size: 17pt !important;
  font-weight: 500 !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 22px !important;
}

.desktop-header {
  padding: 20px 60px;
  margin-bottom: 20px;
  box-shadow: 0 0 6px 6px #ccc;
  display: flex;
  flex-direction: row;
}

.desktop-auth-header {
  flex: 1;
  text-align: right;
}

.desktop-client-logo {
  width: 300px;
  height: 100px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.app-content-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.desktop-left-nav {
  display: flex;
  flex-direction: column;

  .MuiTreeItem-iconContainer {
    display: none;
  }

  .MuiTreeItem-content {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    overflow: hidden;
  }

  .desktop-left-nav-item {
    font-weight: 500;
    padding-left: 15px;
    height: 48px;
    display: flex;
    align-items: center;
    overflow: hidden;
    color: #666;

    .nav-icon {
      margin-right: 30px;
    }

    .nav-label {

    }
  }

  //
  //> a {
  //  padding: 0 20px;
  //  height: 48px;
  //  display: flex;
  //  flex-direction: row;
  //  text-decoration: none;
  //  color: #666;
  //  align-items: center;
  //
  //  &.active {
  //    background-color: #eee;
  //    border-top-right-radius: 24px;
  //    border-bottom-right-radius: 24px;
  //  }
  //
  //  .nav-icon {
  //    margin-right: 30px;
  //  }
  //
  //  .nav-label {
  //
  //  }
  //}
}

.desktop-action-card {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;

  border: solid 1px #bdbdbd;
  border-radius: 12px;
  padding: 20px;
  max-width: 700px;

  display: flex;
  flex-direction: row;
  min-height: 100px;

  &.no-min-height {
    min-height: auto;
  }

  &.bordered {
    .action-separator {
      height: 1px;
      background-color: #999;
      margin: 20px -20px;
    }
  }

  .action {
    .MuiButton-label {
      font-weight: 500 !important;
    }
  }

  .desktop-action-card-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    .header {
      font-weight: 600;
    }

    .action {
      margin: -20px;

      .MuiButton-text {
        text-transform: none;
        padding: 16px;
      }
    }
  }

  .desktop-action-card-detail {
    flex: 1;
    text-align: center;

    h3 {
      text-transform: uppercase;
      font-size: 10pt;
      font-weight: bold;
      margin: 0;
    }

    h4 {
      margin: 0;
      margin-top: 4px;
      color: #c00;
      font-size: 16pt;
    }
  }

  .action-detail-image {
    width: 100%;
    height: 120px;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.profile-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
}

.payment-fields-wrapper {
  > div {
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.payment-option-button {
  font-size: 12px !important;
  margin-bottom: 10px !important;
  font-weight: normal !important;
  line-height: 14px !important;
  text-transform: none !important;

  .MuiButton-label > div {
    width: 100%;
  }
}

@media(min-width: $desktop-width) {
  .app-footer {
    text-align: left;
  }

  .hide-desktop {
    display: none !important;
  }

  .action-list.quad > div {
    &:nth-child(odd) {
      padding-right: 8px;
    }

    &:nth-child(even) {
      padding-left: 8px;
    }

    width: 50%;
    box-sizing: border-box;
    display: inline-block;
  }

  .auth-nav-bar {
    width: auto;
    max-width: none;
    flex-direction: column;
    padding: 0;
    justify-content: space-between;
    height: 100%;
    font-size: 12pt;
  }

  .desktop-left-nav {
    width: 360px;
  }

  .app-footer {
    background-color: #fff;
    padding: 30px;
  }

  .payment-actions-container {
    max-width: 700px;
  }

  .home-container {
    max-width: 950px;
  }

  .home-greeting {
    font-size: 24pt;
  }

  .statements-filter-desktop {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    > a {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      color: #333;
      align-items: center;
      padding: 10px;

      border-top: solid 1px #aaa;

      &:last-child {
        border-bottom: solid 1px #aaa;
      }

      .statement-filter-icon {
        margin-right: 16px;
      }

      .statement-filter-text {
        flex: 1;
      }

      .statement-filter-balance {
        margin-left: 16px;
      }

      &.active {
        background-color: #f6f6f6;
      }
    }
  }

  .statement-container {
    border: solid 1px #999;
    border-radius: 12px;
    box-shadow: 2px 4px 4px 0 #ddd;
    flex: .6;
    padding-top: 30px;

    .statement-amounts-container {
      margin: 0 10px;
    }

    .print-button {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      text-align: left;
    }
  }

  .statement-date {
    margin-top: 10px;
  }

  .login-form {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .login-dialog-left {
    margin-bottom: 10px;
  }

  .statement-header-hr {
    margin: 10px;
    border: solid 1px #eee;
  }

  .profile-container {
    display: flex;
    flex-direction: row;
    max-width: 700px;
    margin-top: 50px;
  }

  .profile-view-container, .statements-container, .home-container, .payment-actions-container {
    padding: 0 40px;
  }

  .profile-section-header-text {
    flex: 1;
    font-weight: 600;
  }

  .desktop-card {
    border: solid 1px #999;
    border-radius: 12px;
    box-shadow: 2px 4px 4px 0 #ddd;
  }

  .payment-form {
    border: solid 1px #999;
    border-radius: 12px;
    box-shadow: 2px 4px 4px 0 #ddd;
    padding: 20px;
    max-width: 700px;
    margin-top: 50px;
  }

  .payment-fields-wrapper {
    display: flex;
    flex-direction: row;

    > div {
      flex: 1;
      padding: 30px 50px;
    }
  }

  .payment-button {
    text-align: right;
  }

  .desktop-payment-header {
    display: flex;
    flex-direction: row;
    font-weight: bold;

    .desktop-payment-header-title {
      flex: 1;
    }

    .desktop-payment-header-balance {
      flex: 1;
      text-align: right;
    }
  }

  .payment-stepper-container {
    margin-top: 50px;
    max-width: 700px;
    padding: 20px;

    border: solid 1px #999 !important;
    border-radius: 12px !important;
    box-shadow: 2px 2px 2px 0 #ddd !important;
  }

  .payment-form-container {
    padding: 0 40px;
  }

  .action-card-row-value-detail-value {
    font-size: 1.2rem;
  }
}

@media(max-width: 1420px) {
  .statements-filter-desktop {
    display: none;
  }
}

@media(min-width: 1421px) {
  .statements-container {
    margin: 0;
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    padding-top: 20px;
  }

  .statements-filter-header {
    text-align: left !important;
    font-weight: bold;
  }

  .statements-filter {
    flex: .4;
    margin-right: 80px;
  }

  .statements-filter-mobile {
    display: none;
  }
}

@media(max-width: #{$desktop-width - 1px}) {
  .hide-mobile {
    display: none !important;
  }

  .header-help-link {
    flex: 1;
  }

  .home-container, .payment-actions-container {
    padding: 0 24px;
  }

  .profile-container {
    padding: 0;
  }

  .profile-section-header {
    justify-content: center;
  }

  .login-form {
    padding: 0;
  }

  .payment-options-wrapper {
    width: 100%;
  }
}

.login-dialog-content {
  display: flex;
}

.login-dialog-left {
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .login-dialog-logo {
    margin: 0 auto;
    width: 250px;
    height: 80px;
    margin-top: 20px;
    margin-bottom: 40px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .login-dialog-close-button {

  }
}

.login-dialog-right {
  flex: 1;
  border-left: solid 1px #999;
  padding: 20px;
}

.MuiPaper-rounded {
  border-radius: 12px !important;
}


@media(min-width: $desktop-width) {
  .generic-login-container {
    box-sizing: border-box;
    border: solid 1px #999;
    border-radius: 12px;
    box-shadow: 2px 4px 4px 0 #ddd;
    padding: 30px 50px;
    max-width: 700px;
    margin: 50px auto 0;
  }
}

@media(max-width: #{$desktop-width - 1px}) {
  .app-footer {
    width: 100%;
  }

  .generic-login-container {
    padding: 0 20px;
  }

  .MuiTab-root {
    padding: 0 !important;
    font-size: 12px !important;
  }
}

.profile-tabs-container {
  max-width: 700px;
  margin: 0 auto 20px;
}

.payment-activity-container {
  max-width: 700px;
  margin: 0 auto;

  .payment-activity-header {
    font-weight: bold;
    padding: 10px 0;
  }

  .payment-activity-list {
    .payment-activity-list-item {
      &:last-child {
        border-bottom: solid 1px #ddd;
      }

      display: flex;
      flex-direction: row;

      border-top: solid 1px #ddd;

      padding: 20px 40px;

      .payment-activity-date {

      }

      .payment-activity-method {
        font-size: 12px;
        color: #666;
      }

      .payment-activity-amount {

      }
    }
  }
}

@media(max-width: #{$desktop-width - 1px}) {
  .payment-activity-container {
    padding: 0 20px;
  }

  .payment-activity-list {
    .payment-activity-list-item {
      padding: 20px 0 !important;
    }
  }
}

.action-card-header {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.no-margin {
    margin-bottom: 0;
  }

  .action-card-header-text {
    flex: 1;
  }
}

.action-card-row-container {
  display: flex;
  flex-direction: row;

  .action-card-row-value {
    flex: 1;

    .action-card-row-value-description {
      font-size: .9rem;

      .MuiTypography-body1 {
        font-size: .9rem;
      }
    }

    .action-card-row-value-sublabel {
      font-size: .9rem;
    }
  }

  .action-card-row-value-detail {
    width: 50%;
    text-align: center;
    padding-left: 20px;

    .action-card-row-value-image {
      max-height: 81px;
      max-width: 100%;
    }

    .action-card-row-value-detail-label {
      font-weight: 600;
    }

    .action-card-row-value-detail-value {
      font-weight: 600;
      color: #c00;
    }
  }
}

.payment-plans-tab-container {
  margin-bottom: 10px;
}

.generic-login-container {
  .app-client-logo {
    margin-bottom: 0;
  }
}

.form-error {
  color: #f00;
}
